import './App.css';
import Navbar from './Components/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import { Grid } from '@mui/material';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { 
  faCheckSquare, 
  faCoffee,
  faCrown,
} from '@fortawesome/free-solid-svg-icons';
import React, { useState, useEffect, Component } from 'react';
import { Palette } from '../Theme/MainTheme';
import { Triangle } from  'react-loader-spinner'

const UNMOUNTED = "unmounted";
const EXITED = "exited";
const ENTERING = "entering";
const ENTERED = "entered";
const EXITING = "exiting";

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 }
};

class FadeInOut extends Component {
  constructor(props) {
    super(props);

    this.state = { status: UNMOUNTED };
  }

  componentDidMount() {
    const { show } = this.props;
    if (show) {
      this.performEnter();
    }
  }

  componentDidUpdate(prevProps) {
    let nextStatus = null;
    if (prevProps !== this.props) {
      const { status } = this.state;
      if (this.props.show) {
        if (status !== ENTERING && status !== ENTERED) {
          nextStatus = ENTERING;
        }
      } else {
        if (status === ENTERING || status === ENTERED) {
          nextStatus = EXITING;
        }
      }
    }
    this.updateStatus(nextStatus);
  }

  updateStatus(nextStatus) {
    if (nextStatus !== null) {
      if (nextStatus === ENTERING) {
        this.performEnter();
      } else {
        this.performExit();
      }
    } else if (this.state.status === EXITED) {
      this.setState({ status: UNMOUNTED });
    }
  }

  performEnter() {
    this.setState({ status: ENTERING }, () => {
      setTimeout(() => {
        this.setState({ status: ENTERED }, () => {});
      }, 0);
    });
  }

  performExit() {
    const { duration } = this.props;
    this.setState({ status: EXITING }, () => {
      setTimeout(() => {
        this.setState({ status: EXITED }, () => {});
      }, duration);
    });
  }

  render() {
    const { status } = this.state;
    if (status === UNMOUNTED) {
      return null;
    }

    const { children, duration, className, style } = this.props;
    return (
      <div
        className={className}
        style={{
          ...style,
          transition: `opacity ${duration}ms ease-in-out`,
          opacity: 1,
          ...transitionStyles[status]
        }}
      >
        {children}
      </div>
    );
  }
}


function App() {
  library.add(fab, faCheckSquare, faCoffee, faCrown);
  const [show, setShow] = useState(true)
  const [finished, setFinished] = useState(false)

  const styles = {
    loadingScreen: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: Palette.darkBlack.main,
      zIndex: 9999,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      opacity: 1,
    },
    loadingScreenFade: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: Palette.darkBlack.main,
      zIndex: 9999,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      opacity: 0,
      transition: "opacity 1s ease"
    }
  }

  useEffect(() => {
    setTimeout(() => setShow(false), 3000)
    setTimeout(() => setFinished(true), 4000)
  }, [])
  

  return (
    <Grid container>
      <div style={show ? styles.loadingScreen : styles.loadingScreenFade} className={finished ? "none" : ""}>
        <Triangle
          height="125"
          width="125"
          color={Palette.golden.main}
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </div>
      
      <Navbar />
      <Outlet />
    </Grid>
  );
}

export default App;
