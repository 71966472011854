import React, { useState, useEffect } from 'react';
import { NavbarItems } from './NavbarItems';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Palette } from '../../../Theme/MainTheme';
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';


const drawerWidth = '22vw';

const Navbar = () => {
    const navigate = useNavigate();
    const onMobile = useMediaQuery('(max-width:700px)');


    const navbarStyles = {
      drawer: {
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: Palette.darkBlack.main,
          color: Palette.silver.main,
        },
      },
      typography: {
        textAlign: 'center',
        fontFamily: 'Rubik',
        fontWeight: 'bold',
        color: Palette.silver.main,
      },
      crownBox: {
        textAlign: 'center',
        color: Palette.silver.main,
        marginTop: '3vh',
        transitionDuration: '500ms',
        cursor: 'pointer',
        '&:hover': {
          color: Palette.golden.main,
        }
      },
      menuButton: {
        paddingTop: '1vh',
        paddingBottom: '1vh',
        marginTop: '0.5vh',
        marginBottom: '0.5vh',
      }
    };

    const DisplayDesktop = () => {
      return(
          <Grid item xs='auto'>
          <Drawer
          sx={navbarStyles.drawer}
          variant="permanent"
          anchor="left">
          <Box sx={navbarStyles.crownBox} onClick={() => navigate('/')}>
            <FontAwesomeIcon icon="crown" size="6x" />
            <Typography variant="h3">Rextech.us</Typography>
          </Box>
          {/* <Typography variant="sub1">
              
          </Typography> */}
          <Typography variant="sub3">
          feel like a King
          </Typography>
  
          <Divider />
            {NavbarItems.map(({route, label}) => (
                <Button 
                key={label}
                color="golden"
                onClick={() => navigate(route)}
                sx={navbarStyles.menuButton}>
                  <Typography sx={navbarStyles.typography}>
                    {label}
                  </Typography>
                </Button>
            ))}
              <Button 
              color="golden"
              onClick={() => {window.open('mailto:rey.cotto1@upr.edu')}}
              sx={navbarStyles.menuButton}>
                <Typography sx={navbarStyles.typography}>
                  Contact Email
                </Typography>
              </Button>
        </Drawer>
        </Grid>
      )
  }

    return (
      <div>{onMobile ? null : <DisplayDesktop/>}</div>
    )
}

export default Navbar;