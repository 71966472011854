import { Box } from "@mui/material";
import { padding } from "@mui/system";
import React from "react";
import { Palette } from "../../../Theme/MainTheme";

const style = {
    box: {
        marginLeft: '22vw',
        backgroundColor: Palette.lightBlack.main, 
        color: 'white', 
        width: '72vw', 
        height: '73vh',
        padding: '1vh 3vw',
        overflowX: 'auto',
        fontFamily: 'Nunito',
        '&::-webkit-scrollbar': {
            width: '4px',
        },
        '&::-webkit-scrollbar-track-piece': {
            border: '5px solid #141414',
            backgroundColor: Palette.lightBlack.main,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: Palette.golden.main,
        },
    },
}

const Content = ({children}) => {
    return(
        <Box sx={style.box}>
            {children}
        </Box>
    )
};

export default Content;