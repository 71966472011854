import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Palette } from "../../../Theme/MainTheme";

const Header = ({title}) => {

    const headerStyles = {
        wrapper: {
            backgroundColor: Palette.lightBlack.main,
            height: '25vh',
            marginLeft: '22vw',
        },
    };

    return(
        <Box sx={headerStyles.wrapper}>
            <Typography variant="h1">
                {title}
            </Typography>
        </Box>
    )
}

export default Header;