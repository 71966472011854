import { createTheme } from "@mui/material/styles";

export const Palette = {
    darkBlack: {
        main: '#111',
    },
    lightBlack: {
        main: '#181818',
    },
    silver: {
        main: '#eee',
    },
    golden: {
        main: '#daaa3b',
    },
};

export const MainTheme = createTheme({
    palette: Palette,
    typography: {
        h1: {
            color: Palette.silver.main,
            textTransform: 'uppercase',
            textAlign: 'center',
            fontFamily: 'Rubik',
            fontSize: '3rem',
            paddingTop: '5rem',
            paddingBottom: '5rem',
            zIndex: 99,
        },
        h3: {
            color: Palette.silver.main,
            userSelect: 'none',
            textTransform: 'uppercase',
            textAlign: 'center',
            fontFamily: 'Rubik',
            fontSize: '1.5rem',
            paddingTop: '1rem',
        },
        sub1: {
            fontFamily: 'Dancing Script',
            fontSize: '1.5rem',
            textAlign: 'center',
            fontWeight: 'bold',
            background: "linear-gradient(180deg, #c67700 0%, #fcff9e 100%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            marginLeft: '-3vw',
            marginTop: '-1vh',
        },
        sub2: {
            fontFamily: 'Kaushan Script',
            fontSize: '2.5rem',
            textAlign: 'center',
            fontWeight: 'bold',
            background: "linear-gradient(180deg, #c67700 0%, #fcff9e 100%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            marginLeft: '6vw',
            marginTop: '-3vh',
            marginBottom: '3vh',
        },
        sub3: {
            fontFamily: 'Kaushan Script',
            fontSize: '2rem',
            textAlign: 'center',
            fontWeight: 'bold',
            background: "linear-gradient(180deg, #c67700 0%, #fcff9e 100%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
            cursor: 'pointer',
            userSelect: 'none',
            marginTop: '-1.5vh',
            marginBottom: '4vh',
        },
        divider: {
            fontFamily: 'Nunito',
            display: 'block',
            fontSize: '1.5rem',
            marginTop: '1vh',
            marginBottom: '1vh',
            borderBottom: '1px solid white'
        }
    },
    components: {},
});