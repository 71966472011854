export const NavbarItems = [
    {
        id: 0,
        icon: '',
        label: 'About Me',
        route: 'aboutme',
    },
    {
        id: 1,
        icon: '',
        label: 'Education',
        route: 'education',
    },
    {
        id: 2,
        icon: '',
        label: 'Experiences',
        route: 'experiences',
    },
    {
        id: 3,
        icon: '',
        label: 'Portfolio',
        route: 'portfolio',
    },
    // {
    //     id: 4,
    //     icon: '',
    //     label: 'Contact',
    //     route: 'contact',
    // },
]