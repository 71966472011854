import './index.css';
import React from 'react';
import App from './App/App';
import reportWebVitals from './Vitals/reportWebVitals';
import AboutMe from './App/Pages/AboutMe/AboutMe'
import Education from './App/Pages/Education/Education'
import Experiences from './App/Pages/Experiences/Experiences'
import Portfolio from './App/Pages/Portfolio/Portfolio'
import Contact from './App/Pages/Contact/Contact'
import { MainTheme } from './Theme/MainTheme';
import { ThemeProvider } from '@mui/material/styles';
import { createRoot } from 'react-dom/client';
import { sendToVercelAnalytics } from './Vitals/vitals';
import { 
    BrowserRouter as Router,
    Routes,
    Route
} from 'react-router-dom';
import Landing from './App/Pages/Landing/Landing';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <ThemeProvider theme={MainTheme}>
        <Router>
            <Routes>
                <Route path="/" element={<App />}>
                    <Route path="/" element={<Landing />} />
                    <Route path="aboutme" element={<AboutMe />} />
                    <Route path="education" element={<Education />} />
                    <Route path="experiences" element={<Experiences />} />
                    <Route path="portfolio" element={<Portfolio />} />
                    <Route path="contact" element={<Contact />} />
                </Route>
            </Routes>
        </Router>
    </ThemeProvider>
);

reportWebVitals(sendToVercelAnalytics);
