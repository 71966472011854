import React from 'react';
import Header from '../../Components/Header/Header';
import Content from '../../Components/Content/Content';
import background from './background.svg'
import sword from './sword.svg'
import stone from './stone.svg'
import { useNavigate } from 'react-router';
import { 
    Box, 
    Grid, 
    Typography
} from '@mui/material';

const Landing = () => {
    const navigate = useNavigate();

    const landingStyles = {
        grab: {
            fontSize: 'calc(1.5vh + 1.5vw)',
            position:'absolute',
            left: '55vw',
            top: '0vh'
        },
        background: {
            height: '130vh',
            position: 'absolute',
            top: '-20vh',
            left: '-20vw',
            zIndex: 0,
        },
        sword: {
            width: '50vw',
            height: '50vh',
            position: 'absolute',
            top: '30vh',
            left: '50vw',
            zIndex: 0,

        },
        stone: {
            width: '40vw',
            height: '40vh',
            position: 'absolute',
            top: '50vh',
            left: '55vw',
            zIndex: 0,

        },
    }

    const grabSword = () => {
        navigate('aboutme');
    }

    return(
        <Grid item xs={true}>
            <Content>
                <Typography variant="h1" sx={landingStyles.grab}>Grab the sword to enter!</Typography>
                <img src={background} alt="background" style={landingStyles.background} />
                <img src={sword} alt="sword" style={landingStyles.sword} className="sword" onClick={grabSword} />
                <img src={stone} alt="stone" style={landingStyles.stone} />
            </Content>
        </Grid>
    )
};

export default Landing;