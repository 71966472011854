import React from 'react';
import { Grid, Typography } from '@mui/material';
import Header from '../../Components/Header/Header';
import Content from '../../Components/Content/Content';

const Education = () => {
    return(
        <Grid item xs={true}>
            <Header title='Education'/>
            <Content>
                <Typography variant="divider"> Career </Typography>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam cursus urna at urna aliquet vestibulum. Donec ac nunc sed turpis efficitur congue sit amet eget nisi. Duis tincidunt tristique elit lacinia malesuada. Proin ut erat eget felis porta dapibus. Pellentesque sit amet sapien dapibus, ornare ex quis, finibus urna. Curabitur ut ipsum at dolor commodo finibus sed quis lacus. Mauris nisi odio, faucibus vitae aliquet interdum, commodo vel risus.<br /><br />
                Nam neque est, mattis porta felis sed, sollicitudin scelerisque eros. Fusce rutrum tempor augue eget vehicula. Nulla tempor efficitur consectetur. Etiam eleifend ex id leo tempus vehicula. In justo magna, tempor vitae ultricies vel, sollicitudin ut dolor. Donec placerat velit quis dapibus dignissim. Duis vulputate dui lectus, eget rutrum tortor lacinia nec.<br /><br />
                Nulla id neque lacus. Nullam posuere eget sapien a pulvinar. Vivamus consequat condimentum lectus ac eleifend. Aliquam et tincidunt justo, at commodo nulla. Etiam euismod diam sit amet magna vestibulum, vel consequat urna condimentum. Suspendisse dictum eros sit amet massa finibus, a facilisis leo finibus. Vivamus sapien metus, maximus vel turpis sit amet, facilisis porttitor leo. Sed consectetur dictum accumsan. Quisque ut venenatis metus, non vestibulum nunc.<br /><br />
                <Typography variant="divider"> Certifications </Typography>
                Aliquam ullamcorper congue purus, dictum porta massa posuere vel. Praesent ornare magna neque, ac ornare enim viverra vel. Quisque eget nibh at tellus scelerisque ultrices. Cras hendrerit orci nec porta facilisis. Phasellus sed varius sem. Etiam turpis magna, venenatis eget auctor quis, aliquam vitae elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum vitae suscipit ipsum. Morbi non diam sed purus dignissim posuere. Suspendisse dignissim eleifend nunc, sit amet molestie eros tempor ac. Phasellus ac risus non magna convallis congue. In vel posuere arcu. Praesent imperdiet faucibus turpis, at bibendum mauris tempor eu. Sed viverra dignissim pellentesque. Phasellus nunc diam, congue id est sit amet, ultrices elementum dolor. Ut quis condimentum sapien.<br /><br />
                Donec suscipit blandit urna blandit dictum. Curabitur vitae vehicula nisi, at efficitur tellus. Fusce sodales accumsan neque in feugiat. Aenean consectetur malesuada ipsum non blandit. Curabitur tristique eu erat non sollicitudin. Proin varius quam sed sagittis sollicitudin. Etiam commodo elit eget tellus consequat, mollis ullamcorper tortor pellentesque. Donec justo metus, semper id lacinia sed, ornare non odio. In tincidunt justo vitae condimentum iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam sed ligula a lorem scelerisque volutpat id in quam. Mauris vel accumsan justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer nec odio erat. Nam eget odio augue.<br /><br />           
                Donec suscipit blandit urna blandit dictum. Curabitur vitae vehicula nisi, at efficitur tellus. Fusce sodales accumsan neque in feugiat. Aenean consectetur malesuada ipsum non blandit. Curabitur tristique eu erat non sollicitudin. Proin varius quam sed sagittis sollicitudin. Etiam commodo elit eget tellus consequat, mollis ullamcorper tortor pellentesque. Donec justo metus, semper id lacinia sed, ornare non odio. In tincidunt justo vitae condimentum iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam sed ligula a lorem scelerisque volutpat id in quam. Mauris vel accumsan justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer nec odio erat. Nam eget odio augue.<br /><br />           
                Donec suscipit blandit urna blandit dictum. Curabitur vitae vehicula nisi, at efficitur tellus. Fusce sodales accumsan neque in feugiat. Aenean consectetur malesuada ipsum non blandit. Curabitur tristique eu erat non sollicitudin. Proin varius quam sed sagittis sollicitudin. Etiam commodo elit eget tellus consequat, mollis ullamcorper tortor pellentesque. Donec justo metus, semper id lacinia sed, ornare non odio. In tincidunt justo vitae condimentum iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam sed ligula a lorem scelerisque volutpat id in quam. Mauris vel accumsan justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer nec odio erat. Nam eget odio augue.<br /><br />           
                Donec suscipit blandit urna blandit dictum. Curabitur vitae vehicula nisi, at efficitur tellus. Fusce sodales accumsan neque in feugiat. Aenean consectetur malesuada ipsum non blandit. Curabitur tristique eu erat non sollicitudin. Proin varius quam sed sagittis sollicitudin. Etiam commodo elit eget tellus consequat, mollis ullamcorper tortor pellentesque. Donec justo metus, semper id lacinia sed, ornare non odio. In tincidunt justo vitae condimentum iaculis. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam sed ligula a lorem scelerisque volutpat id in quam. Mauris vel accumsan justo. Interdum et malesuada fames ac ante ipsum primis in faucibus. Integer nec odio erat. Nam eget odio augue.<br /><br />           
            </Content>
        </Grid>
    )
};

export default Education;